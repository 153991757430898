<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Matriz from "@/components/widgets/network-matriz";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Matriz,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      loading: false,
      matriz: null,
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz").then((response) => {
        if (response.data.status == "success") {
          this.matriz = response.data.matriz;
        } else {
          this.matriz = null;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    this.getMatriz();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0 font-size-18">{{ t("Matriz") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <Matriz :data="matriz"></Matriz>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
